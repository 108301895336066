import React, { useState } from 'react';
import { PostPreview } from 'components';
import {useTranslatableContent} from 'hooks';
import { format } from 'date-fns';
import Slider from 'components/Slider';
import { useEffect } from 'react';
import ReadMoreSlide from 'components/ReadMoreSlide';

const NewsSlider = (props) => {
  const { content } = props;

  const translateObject = useTranslatableContent();

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    let slides = [];
    if (content?.post_list?.length && !content.post_list.every(post => post === null)) {
      slides = content.post_list.map(post => post && <PostPreview
        title={post.title && translateObject(post.title)}
        description={post.description && translateObject(post.description)}
        date={post?.created_at ? format(new Date(post.created_at), 'dd.LL.yy') : null}
        linkText={content.post_button_text}
        path={post?.links?.self || (post.path && translateObject(post.path))}
        image={post.image}
      />);

      if (content.last_item_image) {
        slides.push(
          <ReadMoreSlide
            text={content.last_item_text}
            to={content.last_item_link}
            image={content.last_item_image}
          />
        );
      }
    }
    setSlides(slides);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={`news-slider-section${props?.className ? ` ${props.className}` : ''}`}>
      <div className="container">
        <div className="news-slider">
          {content.title && <h2 className="news-slider__title">{content.title}</h2>}
          {slides && <Slider className="news-slider__swiper-wrap" bulletColor="default" slides={slides}></Slider>}
        </div>
      </div>
    </section>
  );
};

export default NewsSlider;

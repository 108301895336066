import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

export default function Slider (props) {
  if (!props.slides) return null;

  return <div className={`relative${props.className ? ` ${props.className}` : ''}`}>
    <Swiper
      key={props.slides.length}
      modules={[Navigation, Pagination]}
      slidesPerView="auto"
      slidesPerGroup={1}
      spaceBetween={16}
      breakpoints={{
        576: {
          slidesPerView: 'auto',
          spaceBetween: 24,
          slidesPerGroup: 1,
        },
      }}
      centeredSlides={false}
      navigation={true}
      watchSlidesProgress
      pagination={{
        el: ".slider-pagination", // Use a valid DOM element here
        type: "bullets",
        clickable: true,
        bulletClass: `slider-pagination__bullet slider-pagination__bullet--${props.bulletColor}`,
        bulletActiveClass: "is-active",
      }}
    >
      {props.slides.map((slide, index) => (
        <SwiperSlide key={index} className={props.slideClass || ''}>
          {slide}
        </SwiperSlide>
      ))}
      {!props.noPagination && (
        <span className="slider-pagination"></span>
      )}
    </Swiper>
  </div>
}
